// i18next-extract-mark-ns-start capabilities-friction-transmission

import { LINKS, FRICTION_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilityTransmissionEfficienciesPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						{icon && (
							<div className="product-lead-icon image-contain">
								<GatsbyImage
									image={icon}
									alt="WAVE-RT"
									className="icon-image"
								/>
							</div>
						)}
						<p>
							Lorem ipsum!
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_MinimisingLosses")}
						className="widget__sibling__pages">
						<AssetList>
							{FRICTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>
			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default CapabilityTransmissionEfficienciesPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-friction-transmission", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/friction/Realis-Simulation-Transmission.png" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/logos/SABR.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { capabilities: { in: "Friction" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
